.icon {
    width: 3em;
    height: 3em;
}

.alert {
    padding-left: 20px;
    background-color: rgba(236, 232, 26, 1);
    border: 3px solid #13294b;
    border-radius: 20px;
}

.amend-banner {
    width: 100%;
    height: 80px;
    background: rgba(236, 232, 26, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 4px solid rgba(19, 41, 75, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
}

.amend-message {
    width: 80%;
    color: #13294b;
    position: absolute;
    top: 20px;
    left: 120px;
    font-family: sans-serif;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}