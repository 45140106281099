.title {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  text-decoration-line: underline;
  padding-top: 50px;
}

button.primary {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #13294b;
  border-radius: 25px;
  color: #ffffff;
  padding-right: 50px;
  padding-left: 50px;
}

button.secondary {
  background: #ece81a;
  border-radius: 25px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

button.white {
  background: #ffffff;
  border-radius: 25px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.permissions {
  columns: 2 auto;
}

.role {
  width: 263px;
  color: rgba(0, 0, 0, 1);
  font-family: Calibri;
  font-weight: Bold;
  font-size: 24px;
  opacity: 1;
  text-align: left;
}

.permission {
  width: 819px;
  color: rgba(0, 0, 0, 1);
  font-family: Calibri;
  font-weight: Regular;
  font-size: 24px;
  opacity: 1;
  text-align: left;
}

.role-actions-button-group {
  float: right;

  .btn-danger {
    background-color: #dc3545;
    color: white;
  }
}
