@import "./colors.scss";
@import "react-toastify/scss/main.scss";
@import "~bootstrap/scss/bootstrap.scss";

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.main-contanier {
  min-height: 75vh;
}

footer {
  background: $primary;
}

.pill-btn {
  border-radius: 25px;
  padding: 0.5em 2em;
}

.small-label {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding: 4px;
  padding-bottom: 10px;
  margin-left: 15px;
}

.small-label-inline {
  font-weight: 700;
  font-size: 16px;
}

.check-label {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  padding: 4px;
}

.check-input {
  border-color: #13294b;
  font-size: 1rem;
  width: 22px;
  height: 22px;
  border: 1px solid #13294b;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.tertiary-border {
  border: 4px solid $tertiary;
  border-radius: 25px;
}

.data-entry-survey {
  --primary: #eca820;
  --background: #ffffff;
  --background-dim: #f6f4f4;
  --background-dim-light: #ffffff;
  --primary-foreground: #ffffff;
  --foreground: #13294b;
  --base-unit: 6px;
}

.label-subtext {
  font-size: .65em;
  color: $darkgray;
}

.link-primary:hover {
  cursor: pointer;
}


/**************************
  Top Navigation Styles
***************************/
.navbar-header {
  background-color: $tertiary;
  box-shadow: 0 0 0.5rem #000000;
}

.navbar-header a.navbar-brand {
  font-weight: bold;
  color: $white;
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.navbar-header .nav-item,
.navbar-header .nav-item .nav-link {
  color: $secondary;
  font-weight: bold;
  border-radius: 20px;
}

nav.navbar-main {
  background-color: $primary;
  color: $white;
}

nav.navbar-main .nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}

nav.navbar-main .nav-link:hover,
nav.navbar-main .dropdown.show {
  background-color: $secondary;
  color: $primary;
  cursor: pointer;
}

nav.navbar-main .nav-item:hover,
nav.navbar-main .nav-item:hover .nav-link {
  color: $primary;
  cursor: pointer;
}

nav.navbar-main .nav-link.active,
nav.navbar-main .nav-link.active {
  background-color: $secondary;
  color: $primary;
}

nav.navbar-secondary {
  background-color: $secondary;
  color: $primary;
}

nav.navbar-secondary .nav-item,
nav.navbar-secondary .nav-item .nav-link {
  color: $primary;
}

nav.navbar-main .dropdown .nav-user-icon {
  padding: 5px;
}

nav.navbar-main .dropdown.show .nav-user-icon path,
.dropdown .nav-user-icon:hover path {
  fill: $primary !important;
}

/**************************
  Stepper Component Styles
***************************/
#stepper-wrapper .step-nav-container .step-nav-item.active {
  background-color: white;
}

#stepper-wrapper .step-nav-container .step-nav-item {
  width: 4em;
  cursor: pointer;
  height: 4em;
  margin: 0px auto;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

#stepper-wrapper .step-nav-container .step-nav-item span {
  font-size: calc(2em);
  font-variant-numeric: tabular-nums;
}

#stepper-wrapper .step-nav-container .btn-stepper-next {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}

.btn-stepper-next {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  color: $primary;
  background-color: white;
  padding: 0;
  border: none;
}

/**************************
  Change the Theme
***************************/
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

/**************************
  Bootstrap Overrides
***************************/
.col-text-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.label {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  padding: 10px;
  padding-bottom: 10px;
  margin-right: -50px;
}

.offcanvas.show {
  visibility: visible;
}

.offcanvas-body {
  padding: 0;
}

/**************************
  SurveyJS Overrides
***************************/
.sd-row .sd-question__content .sd-expression {
  margin-top: 8px;
}

.sd-description.sd-panel__description {
  font-size: .75em;
}

.hide {
  display: none;
}

/**************************
  Dialog Box / Read More
***************************/

.dialogBox {
  width: 60vw;
  padding: 3em;
}

.dialogBox-footer {
  padding-top: 3em;
}
