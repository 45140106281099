#announcements {
  height: 100%;
}

#resources {
  min-height: 30vh;
}

#contacts {
  min-height: 25vh;
}

.ndh-setting {
  cursor: pointer;
}

.moveable-item:hover {
  cursor: move;
}

.moveable-item a:hover {
  cursor: pointer;
}

.submit-btn-group {
  height: 3em;
}
