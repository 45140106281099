p.title {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  text-decoration-line: underline;
}

button.primary {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #13294b;
  border-radius: 25px;
  color: #ffffff;
  padding-right: 50px;
  padding-left: 50px;
}

button.secondary {
  background: #ece81a;
  border-radius: 25px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

button.white {
  background: #ffffff;
  border-radius: 25px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

button.btn-danger {
  background-color: #dc3545;
  color: white;
}

.form-check .check-label {
  font-style: normal;
  font-size: 16px;
  line-height: 29px;
  padding: 0px;
  padding-left: 10px;
}

.form-check-input {
  margin-left: 10px !important;
}

.form-label {
  font-size: 16px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.small-label {
  font-size: 14px;
}