.sd-multipletext__item-title {
    width: 175px;
}

.sd-element.sd-element--complex {
    padding: 4% 6%;
}

.sd-element.sd-question {
    padding-top: 0;
}

:not(.svc-row--ghost)>.sd-row {
    min-height: 30px;
}

.sd-input.sd-text {
    margin: 5px 0 20px 0;
}