.data-entry-survey {
  --primary: #eca820;
  --background: #ffffff;
  --background-dim: #f6f4f4;
  --background-dim-light: #ffffff;
  --primary-foreground: #ffffff;
  --foreground: #13294b;
  --base-unit: 6px;
}

.div-load {
  width: 100%;
  height: 20px;
  background: #ffffff;
}

body .sv-list__item--selected {
  background-color: #eca820 !important;
}