.selected {
    background: #ECA820;
    border-radius: 25px;
    width: 252px;
    left: 22px;
    top: 298px;
}

.unselected {
    background: #FFF;
    border-radius: 25px;
    width: 252px;
    left: 22px;
    top: 298px;
}