
/* Provide sufficient contrast against white background */

$primary: #13294B;
$secondary: #ECE81A;
$tertiary: #ECA820;
$white: #FFFFFF;
$gray: #CCCCCC;
$darkgray: #555555;

/* Global SurveyJS CSS var overrides */
:root {
    --primary: #eca820;
    --background: #ffffff;
    --background-dim: #f6f4f4;
    --background-dim-light: #ffffff;
    --primary-foreground: #ffffff;
    --foreground: #13294b;
    --base-unit: 6px;
  }
