$font-family: var(--font-family);

.sv_progress-buttons__container-center {
  text-align: center;
}

.sv_progress-buttons__container {
  display: inline-block;
  font-size: 0;
  width: 100%;
  max-width: 1100px;
  white-space: nowrap;
  overflow: hidden;
}

.sv_progress-buttons__image-button-left {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iMTEsMTIgOSwxNCAzLDggOSwyIDExLDQgNyw4ICIvPg0KPC9zdmc+DQo=);
}

.sv_progress-buttons__image-button-right {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iNSw0IDcsMiAxMyw4IDcsMTQgNSwxMiA5LDggIi8+DQo8L3N2Zz4NCg==);
}

.sv_progress-buttons__image-button--hidden {
  visibility: hidden;
}

.sv_progress-buttons__list-container {
  max-width: calc(100% - 36px);
  display: inline-block;
  overflow: hidden;
}

.sv_progress-buttons__list {
  display: inline-block;
  width: max-content;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.sv_progress-buttons__list li {
  width: 138px;
  font-size: 14px;
  font-family: var(--font-family, $font-family);
  position: relative;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.sv_progress-buttons__list li:before {
  width: 24px;
  height: 24px;
  content: "";
  line-height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border: 3px solid;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
}

.sv_progress-buttons__list li:after {
  width: 73%;
  height: 3px;
  content: "";
  position: absolute;
  top: 15px;
  left: -36.5%;
}

.sv_progress-buttons__list li:first-child:after {
  content: none;
}

.sv_progress-buttons__list .sv_progress-buttons__page-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.sv_progress-buttons__list .sv_progress-buttons__page-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--nonclickable:before {
  cursor: not-allowed;
}