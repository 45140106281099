
@import '../../sass/colors.scss';


#stepper-wrapper .step-nav-container .step-nav-item {
  border: 0.25em solid $primary;
  color: $primary !important;
}

#stepper-wrapper .step-nav-container .step-nav-item.selected-step{
  background-color: #ECE81A !important;
}

#stepper-wrapper .step-nav-container .step-nav-item.completed{
  color: $white !important;
}

#stepper-wrapper .step-nav-container .step-nav-item.completed span{
  color: $white !important;
}

#stepper-wrapper .step-nav-container .step-nav-item span{
  color: $primary !important;
}
